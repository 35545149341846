import './hero-banner.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import Slide1 from '../../assets/images/benifits-banner.jpg';
/* eslint-disable-next-line */
export interface HeroBannerProps {}

export function HeroBanner(props: HeroBannerProps) {
  return (
    <div className="heroBanner">
      <div className="bgHolder">
        <img src={Slide1} alt="Slide" />
      </div>
      <div className="titleHold">
        <h2>
          <span>Zero Trust in minutes</span>{' '}
        </h2>
      </div>
      <Carousel
        fade
        interval={5000}
        pause={false}
        indicators={true}
        controls={false}
      >
        <Carousel.Item>
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <h3>Automate, orchestrate and simplify IT</h3>
                <p className="normal-text">
                  increase operational efficiencies and reduce work hours and
                  stress for both business and IT staff.
                </p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <h3>Consolidate audit and security controls</h3>
                <p className="normal-text">under a single pane of glass.</p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <h3>Enterprise level features</h3>
                <p className="normal-text">
                  at 1/10th the cost and complexity of today’s solutions.
                </p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <h3>Optimize user experience</h3>
                <p className="normal-text">
                  for both employees and customers by reducing friction and
                  ensuring data integrity for all users.
                </p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Carousel.Caption>
            <div className="">
              <div className="content">
                <h3>Enterprise level features</h3>
                <p className="normal-text">
                  at 1/10th the cost and complexity of today’s solutions.
                </p>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default HeroBanner;
