import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import BannerInner from '../../components/banner-inner/banner-inner';
import HowWeHelp from '../../components/how-we-help/how-we-help';
import Contact from '../../components/contact/contact';
import Solution from '../../assets/images/solution-bg.jpg';
import iconClipboard from '../../assets/images/icon-clipboard.png';
import iconSmileyface from '../../assets/images/icon-smileyface.png';
import iconDownArrow from '../../assets/images/icon-down-arrow-dollar.png';
import iconhandshake from '../../assets/images/icon-handshake.png';
import iconBlockChain from '../../assets/images/icon-block-chain.png';
import iconClipBoard from '../../assets/images/icon-clipboard-shield.png';

import './index.scss';
import Layout from '../../layout';
import { Container, Row, Col } from 'react-bootstrap';
import HeroBanner from '../../components/hero-banner/hero-banner';
import Helmet from 'react-helmet';
import Slide1 from '../../assets/images/slide-1.jpg';
const { marketingWebUrl } = process.env;
const ogImageUrl = marketingWebUrl + Slide1;

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Helmet title="Benefits | Authorization Re-Imagined">
        {/* General tags */}
        <meta name="description" content="" />
        <meta name="image" content={ogImageUrl} />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Benefits | Authorization Re-Imagined"
        />
        <meta property="og:description" content="Authorization Re-Imagined" />
        <meta property="og:url" content={marketingWebUrl} />
        <meta property="og:site_name" content="Syccure" />
        <meta property="og:image" content={ogImageUrl} />
      </Helmet>
      <Header headerClass={'header haeder-benefits'}></Header>
      <HeroBanner />
      <Container>
        <div className="beniftsRow">
          <Row>
            <Col lg="2" md="3">
              <div className="iconHold"
              
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <img src={iconClipboard} alt="Icon Clipboard" />
              </div>
            </Col>
            <Col lg="10" md="9">
              <div className="textHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500"
              >
                <h3>Complete authorization management</h3>
                <p>
                  Clear visibility and control of all policy management
                  decisions surrounding your data
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="2" md="3">
              <div className="iconHold"
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <img src={iconSmileyface} alt="Icon Smiley Face" />
              </div>
            </Col>
            <Col lg="10" md="9">
              <div className="textHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500"
              >
                <h3>Easy to use</h3>
                <p>
                  Policy as Code solution – fast and easy to deploy and manage
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="2" md="3">
              <div className="iconHold"
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <img src={iconDownArrow} alt="Icon Down Arrow" />
              </div>
            </Col>
            <Col lg="10" md="9">
              <div className="textHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500">
                <h3>Reduce costs and risk</h3>
                <p>Enterprise benefits priced for SMB’s</p>
              </div>
            </Col>
          </Row>
        </div>

      </Container>
      <div className="alt-bg">
        <Container>
        <div className="beniftsRow">
          <Row>
            <Col lg="10" md="9">
              <div className="textHold"
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <h3>Trust across boundaries</h3>
                <p>Share information without exposing your networks</p>
              </div>
            </Col>
            <Col lg="2" md="3">
              <div className="iconHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500"
              >
                <img src={iconhandshake} alt="Icon Clipboard" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="10" md="9">
              <div className="textHold"
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <h3>Blockchain architecture</h3>
                <p>
                  Prevent policy conflicts and enable automatic non-invasive
                  audits
                </p>
              </div>
            </Col>
            <Col lg="2" md="3">
              <div className="iconHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500"
              >
                <img src={iconBlockChain} alt="Icon Smiley Face" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="10">
              <div className="textHold"
              data-sal="fade"
              data-sal-duration="1500"
              data-sal-easing="ease"
              >
                <h3>Integrated security and compliance</h3>
                <p>Automate compliance through policy management</p>
              </div>
            </Col>
            <Col lg="2" md="2">
              <div className="iconHold"
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              ata-sal-delay="500"
              >
                <img src={iconClipBoard} alt="Icon Down Arrow" />
              </div>
            </Col>
          </Row>
        </div>
        </Container>
      </div>
      <Contact></Contact>
      <Footer></Footer>
    </Layout>
  );
}

export default Index;
